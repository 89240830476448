import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
import { RAFEntityBase } from './../../../RAFComponents/models/Common/RAFEntityBase';

export class PolicyStatementRow {
    Action?: string[];
    Resource?: string[];
}

export class IRolePermission {
    UID?: string;
    RoleUID?: string;
    Role?: string;
    PermissionJson?: PolicyStatementRow;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class IRolePermissionDto {
    UID?: string;
    RoleUID?: string;
    Role?: string;
    PermissionJson?: PolicyStatementRow;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class RolePermissionRow extends IRolePermission implements RAFEntityBase, ILookupRow {
    getClassName?() {//use entityname
        return 'role';
    }
    getIdField?(): string {
        return propertyOf<RolePermissionRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "RolePermission/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "RolePermission/Save";
    }
    getGroupByUrl?(): string {
        return "RolePermission/GroupBy";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "RolePermission/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "RolePermission/RelatedList";
    }
}
