import { EntitySettingsRow } from "../../../RAFMaster/RMModules/Entity/EntitySettingsRow";
import { RAFDataModelComponentRow } from "../../../RAFMaster/RMModules/RAFPage/PageLayoutJM";
import { RAFCustomFilter } from "../../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { propertyOf } from "../../helpers/utils";
import { RAFEntityBase } from "./RAFEntityBase";

export class IFormLibraryDto {
    UID?: string;
    Title?: string;
    Revision?: number;
    IsActive?: boolean;
    FormType?: string;
    Entity?: string;
    DataModel?: RAFDataModelComponentRow;
    FormStructure?: any;
    // DataModel?: RAFFormIOModel | RAFFormDataModel | { layout?: RAFPageBuilderRow[]; } | { components: RAFDataModelRow[]; };
    VersionUID?: string;
    VersionNumber?: number;
    CurrentStatus?: string;
    TagsListJson?: string[];
    IconName?: string;
    PageType?: string;
    IsSystem?: boolean;
    Category?: string;
    CategoryUID?: string;
    CategoryType?: string;
    URLAccess?: number;
    QRAccess?: number;
    PortalUID?: string;
    Theme?: string;
    FormRules?: RAFRuleAction[];
    //Style?:string;//Page,Wizard,Tab
    //TagsListJsonText?: string;
    RelatedToType?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class IFormLibraryRow {
    UID?: string;
    Title?: string;
    Revision?: number;
    IsActive?: boolean;
    FormType?: string;
    Entity?: string;
    FormStructure?: any;
    DataModel?: RAFDataModelComponentRow;
    //DataModel?: RAFFormIOModel | RAFFormDataModel | { layout?: RAFPageBuilderRow[]; } | { components: RAFDataModelRow[] };
    VersionUID?: string;
    VersionNumber?: number;
    CurrentStatus?: string;
    TagsListJson?: string[];
    IconName?: string;
    PageType?: string;
    IsSystem?: boolean;
    Category?: string;
    CategoryUID?: string;
    CategoryType?: string;
    URLAccess?: number;
    QRAccess?: number;
    PortalUID?: string;
    Theme?: string;
    FormRules?: RAFRuleAction[];
    //Style?:string;//Page,Wizard,Tab
    //TagsListJsonText?: string;
    RelatedToType?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export enum RAFFormType {
    UserForm = "User Form",
    Widget = "Widget",
    FormComponent = "Form Component",
    FormTemplate = "Form Template",
    RelatedForm = "Related Form",
    ChildForm = "Child Form",
    FormFlow = "Form Flow",
}

export enum RAFFormStyle {
    Default = "Default",
    Page = "Page",
    Wizard = "Wizard",
    Tab = "Tab",
    Dialog = "Dialog",
}

export class RAFRuleAction {
    Condition?: RAFCustomFilter;
    //RRuleModel1?: RuleModel;
    RRuleModel?: RAFRRuleModel;
    Action?: RAFRuleActionParams[];
}

class RAFRuleActionParams {
    Type?: string;
    Value?: string;
}

export class RAFRRuleModel {
    Condition?: string;
    Field?: string;
    Label?: string;
    Not?: boolean;
    Operator?: string;
    Type?: string;
    Value?: any;
    Rules?: RAFRRuleModel[];
}



export class FormLibraryRow extends IFormLibraryRow implements RAFEntityBase {
    FormLibrarySettings?: EntitySettingsRow;
    FormData?: string;
    CollectionName?: string;
    ResponsiveMode?: string;

    getClassName?() {
        return 'form_library';
    }
    getIdField?(): string {
        return propertyOf<FormLibraryRow>("Title");
    }
    getListUrl?(): string {
        return "FormLibrary/List";
    }
    getSaveUrl?(): string {
        return "FormLibrary/Save";
    }
    getLookupUrl?(): string {
        return "FormLibrary/Lookup";
    }
}


