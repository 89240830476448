export class ILookupRow {
    getLookupUrl(): string {
        return "";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}

export class ILookupGroupRow extends ILookupRow{
    getGroupField(): string {
        return "ClientName";
    }
}

