import React, { useState } from 'react';

function RAFImageWithFallback({ src, fallbackSrc, alt='', className='' }) {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      onError={handleImageError}
    />
  );
}

export default RAFImageWithFallback;
